import React, { useState, useEffect } from "react";

// core components
import { makeStyles } from "@material-ui/core/styles";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import TextField from "@material-ui/core/TextField";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Paper, Button, Box, Typography } from "@material-ui/core";

//libs
import { useFormik } from "formik";
import * as yup from "yup";
import useUser from "hooks/useUser";

//services
import PaymentService from "services/PaymentService";
import { financial } from "helpers";
import PaymentMethods from "components/PaymentMethods/PaymentMethods";
import ActionAlerts, { AlertSeverity } from "components/ActionAlerts/ActionAlerts";
import usePaymentHandler from "hooks/usePaymentHandler";
import usePaymentGateway from "hooks/usePaymentGateway";

const validationSchema = {
    amountToBeCharged: yup.number().required().min(1),
    reason: yup.string().required(),
};

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        alignItems: "center",
    },
    wrapper: {
        margin: theme.spacing(1),
        position: "relative",
    },
    buttonSuccess: {
        "&:hover": {},
    },
    fabProgress: {
        position: "absolute",
        top: -6,
        left: -6,
        zIndex: 1,
    },
    buttonProgress: {
        position: "absolute",
        top: "50%",
        left: "50%",
        marginTop: -12,
        marginLeft: -12,
    },
}));

const paymentGatewayIds = {
    PAYPAL_PRO: 2,
    AUTHORIZE_NET: 8,
    STRIPE: 9,
};

export default function CCchargeForm({
    dealerId,
    customerId,
    orderId,
    fullAmount = 0,
    pgatewayId = null,
    billingInfo = {},
    onSuccess = () => {},
    onError = () => {},
}) {
    const classes = useStyles();
    const [user] = useUser();
    const { loading, postPaypalPro, postAuthorizeNet, postStripe } = usePaymentHandler();
    const { resolvedGatewayId, errorMessage } = usePaymentGateway(customerId, pgatewayId);

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            dealerId,
            customerId,
            orderId,
            userId: user?.id || 0,
            fullAmount: Number(fullAmount),
            amountToBeCharged: 0,
            reason: "",
        },
        validationSchema: yup.object(validationSchema),
        onSubmit: async (values) => {
            const confirmMessage = `Do you want to proceed with the charge of $${financial(
                values.amountToBeCharged
            )}?`;

            const confirmCharge = window.confirm(confirmMessage);

            if (confirmCharge) {
                if (!resolvedGatewayId) {
                    onError("No payment gateway selected or valid payment info found.");
                    return;
                }

                const bodyParsed = {
                    dealerId: values.dealerId,
                    cId: values.customerId,
                    transactionAmount: values.amountToBeCharged,
                };

                switch (resolvedGatewayId) {
                    case paymentGatewayIds.PAYPAL_PRO: // PayPal Pro
                        await postPaypalPro(bodyParsed, onSuccess, onError);
                        break;
                    case paymentGatewayIds.AUTHORIZE_NET: // Authorize.Net
                        await postAuthorizeNet(bodyParsed, onSuccess, onError);
                        break;
                    case paymentGatewayIds.STRIPE: // Stripe
                        await postStripe(bodyParsed, onSuccess, onError);
                        break;
                    default:
                        throw new Error("No payment gateway selected");
                }
            }
        },
    });

    /*const postPaypalPro = async (body, setLoading = () => {}) => {
        try {
            const res = await PaymentService.postPaypalPro({
                dealerId: body.dealerId,
                customerId: body.customerId,
                transactionAmount: body.amountToBeCharged,
            })
                .then((response) =>
                    onSuccess(
                        "The payment has been successfully processed. The amount of $" +
                            financial(body.amountToBeCharged) +
                            " has been charged to the credit card."
                    )
                )
                .catch(function (error) {
                    onError(
                        "The action could not be completed because the initial transaction information was not found. This may be due to the payment gateway not being implemented or the order not containing the credit card (CC) information."
                    );
                });

            setLoading(false);
        } catch (error) {
            setLoading(false);
            onError(
                "The action could not be completed because the initial transaction information was not found. This may be due to the payment gateway not being implemented or the order not containing the credit card (CC) information."
            );
        }
    };

    const postAuthorizeNet = async (body, setLoading = () => {}) => {
        try {
            const res = await PaymentService.postAuthorizeNet({
                dealerId: body.dealerId,
                customerId: body.customerId,
                transactionAmount: body.amountToBeCharged,
            })
                .then((response) =>
                    onSuccess(
                        "The payment has been successfully processed. The amount of $" +
                            financial(body.amountToBeCharged) +
                            " has been charged to the credit card."
                    )
                )
                .catch(function (error) {
                    onError(
                        "The action could not be completed because the initial transaction information was not found. This may be due to the payment gateway not being implemented or the order not containing the credit card (CC) information."
                    );
                });
        } catch (error) {
            setLoading(false);
            onError(
                "The action could not be completed because the initial transaction information was not found. This may be due to the payment gateway not being implemented or the order not containing the credit card (CC) information."
            );
        }
    };

    const postPayment = async (body) => {
        try {
            await PaymentService.post({
                dealerId: body.dealerId,
                orderId: body.orderId,
                userId: body.userId,
                transactionAmount: body.amountToBeCharged,
                reason: body.reason,
            })
                .then((response) => onSuccess())
                .catch(function (error) {
                    if (error.response) {
                        onError(error.response.data.message);
                    }
                });
        } catch (error) {
            console.error(error);
        }
    };*/

    return (
        <>
            <form onSubmit={formik.handleSubmit}>
                <Box style={{ marginBottom: 10 }}>
                    {resolvedGatewayId ? (
                        <Typography
                            variant="subtitle1"
                            gutterBottom
                            style={{ textTransform: "math-auto", fontSize: 14 }}
                        >
                            (*) The charge will be processed using the customer's stored profile or
                            token.
                        </Typography>
                    ) : (
                        <>
                            <ActionAlerts
                                message={errorMessage}
                                severity={AlertSeverity.ERROR}
                                autoCloseTime={null}
                                onClose={() => {}}
                            />
                        </>
                    )}
                </Box>

                <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                        <TextField
                            type="number"
                            fullWidth
                            margin="dense"
                            id="amountToBeCharged"
                            name="amountToBeCharged"
                            label="Amount to be charged"
                            value={formik.values.amountToBeCharged}
                            onChange={formik.handleChange}
                            error={
                                formik.touched.amountToBeCharged &&
                                Boolean(formik.errors.amountToBeCharged)
                            }
                        />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                        <TextField
                            fullWidth
                            margin="dense"
                            id="reason"
                            name="reason"
                            label="Reason"
                            multiline
                            rows={4}
                            value={formik.values.reason}
                            onChange={formik.handleChange}
                            error={formik.touched.reason && Boolean(formik.errors.reason)}
                            inputProps={{
                                maxLength: 255,
                            }}
                        />
                    </GridItem>
                    {resolvedGatewayId && (
                        <GridItem
                            xs={12}
                            sm={12}
                            md={12}
                            style={{ textAlign: "center", marginTop: 10 }}
                        >
                            <div className={classes.wrapper}>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    disabled={loading}
                                >
                                    {loading ? "Processing charge..." : "Confirm Charge"}
                                </Button>

                                {loading && (
                                    <CircularProgress
                                        size={24}
                                        className={classes.buttonProgress}
                                    />
                                )}
                            </div>
                        </GridItem>
                    )}
                </GridContainer>
            </form>

            {!resolvedGatewayId && (
                <Box style={{ marginTop: 10 }}>
                    <PaymentMethods
                        dealerId={dealerId}
                        amountToBeCharged={formik.values.amountToBeCharged}
                        billingInfo={billingInfo}
                        onSuccess={onSuccess}
                    />
                </Box>
            )}
        </>
    );
}
