import http, { BASE_URL_API_ECOMMERCE } from "./Http";

const post = async ({ dealerId, orderId, userId, transactionAmount, reason }) => {
    const res = await http
        .post(`/merchante-api`, {
            dealerId,
            orderId: Number(orderId),
            userId,
            transactionAmount: Number(transactionAmount),
            reason,
        })
        .then((r) => r.data);
    return res;
};

const postPaypalPro = async ({
    dealerId,
    cId,
    transactionAmount,
    cardNumber,
    validThru,
    cvc,
    billCompany,
    lastName = "",
    billAddress1,
    billCity,
    billState,
    billZip,
    billCountry = "USA",
}) => {
    const res = await http
        .post(`${BASE_URL_API_ECOMMERCE}/payment/authorization`, {
            dealerId,
            cId: cId,
            CURRENCY: "USD",
            AMT: String(transactionAmount),
            ACCT: cardNumber,
            EXPDATE: validThru,
            CVV2: cvc,
            BILLTOFIRSTNAME: billCompany,
            BILLTOLASTNAME: lastName,
            BILLTOSTREET: billAddress1,
            BILLTOCITY: billCity,
            BILLTOSTATE: billState,
            BILLTOZIP: billZip,
            BILLTOCOUNTRY: billCountry,
        })
        .then((r) => r.data);
    return res;
};

const postAuthorizeNet = async ({
    dealerId,
    cId,
    transactionAmount,
    cardNumber,
    validThru,
    cvc,
}) => {
    const res = await http
        .post(`${BASE_URL_API_ECOMMERCE}/authorize-net`, {
            dealerId,
            cId: cId,
            transactionAmount: Number(transactionAmount),
            cardNumber: cardNumber,
            cardExpDate: validThru,
            cvv2: cvc,
        })
        .then((r) => r.data);
    return res;
};

const postStripe = async ({ dealerId, cId, transactionAmount, currency = "usd" }) => {
    const res = await http
        .post(`${BASE_URL_API_ECOMMERCE}/stripe`, {
            dealerId,
            cId,
            amount: Number(transactionAmount),
            currency,
        })
        .then((r) => r.data);
    return res;
};

const PaymentService = {
    post,
    postPaypalPro,
    postAuthorizeNet,
    postStripe,
};

export default PaymentService;
