import React, { useState, useEffect, useRef } from "react";

// @material-ui/icons
import Assignment from "@material-ui/icons/Assignment";
import SearchIcon from "@material-ui/icons/Search";
import Edit from "@material-ui/icons/Edit";
import Close from "@material-ui/icons/Close";

// core components
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import Tooltip from "@material-ui/core/Tooltip";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import Notification from "components/Notification/Notification.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import ReactTable from "components/ReactTable/ReactTable.js";
import Modal from "components/Modal/Modal.js";

// custom components
import CreateCustomer, { INITIAL_VALUES } from "components/CreateCustomer/CreateCustomer";
import AreYouSure from "components/AreYouSure/AreYouSure";
import FormattedDate from "components/FormattedDate/FormattedDate";

//libs
import memoize from "fast-memoize";
import { format } from "date-fns";

//service
import CustomerService from "../../services/CustomerService";
import DealerService from "../../services/DealerService";

import useUser from "hooks/useUser";
import DeleteButton from "components/DeleteButton/DeleteButton";
import DealerPricingService from "services/DealerPricingService";

export default function Orders() {
    const [user] = useUser();
    const [dlid, setDlid] = useState(0);
    const [dealers, setDealers] = useState([]);
    const [open, setOpen] = useState(false);
    const [form, setForm] = useState("create");
    const [initialValues, setInitialValues] = useState(INITIAL_VALUES);
    const [customers, setCustomers] = useState([]);
    const [search, setSearch] = useState("");
    const [pageCount, setPageCount] = useState(0);
    const fetchIdRef = useRef(0);
    const [modal, setModal] = useState({
        open: false,
        title: "Edit customer",
    });
    const [notification, setNotification] = useState({
        open: false,
        color: "success",
        message: "Customer created successfully",
    });

    useEffect(() => {
        const getDealers = async (dlid) => {
            const dealers = await DealerService.getAll(dlid).then((o) => o.data);
            if (dealers.length === 1) setDlid(dealers[0]?.id);
            setDealers(dealers);
        };
        if (user?.isAdmin === 0) {
            getDealers(user?.dealerId);
        } else {
            getDealers("");
        }
    }, [dlid, user?.dealerId]);

    const columns = [
        {
            Header: "Name",
            accessor: "billCompany",
        },
        {
            Header: "Email",
            accessor: "billEmail",
        },
        {
            Header: () => <div style={{ textAlign: "center" }}>Qty orders</div>,
            accessor: "qtyOrders",
            Cell: ({
                row: {
                    original: { qtyOrders },
                },
            }) => {
                return <div style={{ textAlign: "center" }}>{qtyOrders}</div>;
            },
        },
        {
            Header: () => <div style={{ textAlign: "center" }}>Total Amount</div>,
            accessor: "amountOrders",
            Cell: ({
                row: {
                    original: { amountOrders },
                },
            }) => {
                return <div style={{ textAlign: "right" }}>${amountOrders}</div>;
            },
        },
        {
            Header: () => <div style={{ textAlign: "center" }}>Registration Date</div>,
            accessor: "createdAt",
            sortType: (rowA, rowB, columnId) => {
                const dateA = new Date(rowA.values[columnId]);
                console.log(rowA.values[columnId], "=>", dateA);
                const dateB = new Date(rowB.values[columnId]);
                return dateA - dateB;
            },
            Cell: ({
                row: {
                    original: { createdAt },
                },
            }) => {
                return (
                    <div style={{ textAlign: "center" }}>
                        <FormattedDate date={createdAt} />
                    </div>
                );
            },
        },
        {
            Header: "Actions",
            Cell: ({ row: { original } }) => {
                const cid = original.id;
                const { qtyOrders, amountOrders, ...initVals } = original;

                return (
                    <>
                        <div className="actions-right">
                            <Button
                                justIcon
                                round
                                simple
                                color="warning"
                                className="edit"
                                onClick={async () => {
                                    const {
                                        data: dealerPricing = [],
                                    } = await DealerPricingService.getAll(original.dealerId);

                                    let costPlus = "";
                                    let listMinus = "";

                                    if (dealerPricing.some((dp) => dp.customerId == cid)) {
                                        const dp = dealerPricing.find((d) => d.customerId == cid);
                                        costPlus = dp.costPlus;
                                        listMinus = dp.listMinus;
                                    }

                                    setForm("edit");
                                    setModal({ ...modal, open: true, title: "Edit customer" });
                                    setNotification({
                                        ...notification,
                                        message: "Customer updated successfully",
                                    });
                                    setInitialValues({ ...initVals, costPlus, listMinus });
                                }}
                            >
                                <Edit />
                            </Button>
                            {qtyOrders === 0 ? (
                                <>
                                    <DeleteButton
                                        title="customer account"
                                        handleClick={async () => {
                                            await CustomerService.del(initVals.id).then(
                                                (o) => o.data
                                            );
                                            setCustomers(
                                                [...customers].filter((c) => c.id !== initVals.id)
                                            );
                                            setOpen(false);
                                        }}
                                    />
                                    {/*<Button
                                    justIcon
                                    round
                                    simple
                                    color="danger"
                                    className="edit"
                                    onClick={() => {
                                        setInitialValues(initVals);
                                        setOpen(true);
                                    }}
                                >
                                    <Close />
                                </Button>*/}
                                </>
                            ) : (
                                <Tooltip title="The account cannot be deleted due to an existing purchase history">
                                    <span>
                                        <DeleteButton disabled />
                                    </span>
                                    {/*<Button
                                        justIcon
                                        round
                                        simple
                                        color="danger"
                                        className="edit"
                                        onClick={() => {}}
                                    >
                                        <Close />
                                    </Button>*/}
                                </Tooltip>
                            )}

                            {/*<PasswordManagement userId={initVals.id} userType="CUSTOMER" userName={initVals.billEmail} />*/}
                        </div>
                    </>
                );
            },
        },
    ];

    const fetchData = React.useCallback(
        memoize((dlid, search) => async ({ pageSize, pageIndex }) => {
            const fetchId = ++fetchIdRef.current;

            if (fetchId === fetchIdRef.current) {
                const startRow = pageSize * pageIndex;

                const customers = await CustomerService.getAll(
                    dlid,
                    search,
                    startRow,
                    pageSize
                ).then((x) => x.data);
                setCustomers(customers.data);
                setPageCount(Math.ceil(customers.recordsTotal / pageSize));
            }
        }),
        []
    );

    const onSuccess = (customer) => {
        if (form === "create") {
            setCustomers([...customers, customer]);
        }

        if (form === "edit") {
            const nCustomers = customers.map((c) =>
                c.id === customer.id ? { ...c, ...customer } : c
            );
            setCustomers(nCustomers);
        }

        setInitialValues({
            ...INITIAL_VALUES,
            id: Math.random(),
        });

        setModal({ ...modal, open: false });

        setNotification({
            ...notification,
            open: true,
        });
    };

    return (
        <>
            <AreYouSure
                open={open}
                title={`Are you sure you?`}
                subtitle={`The record will be deleted`}
                onAccept={async () => {
                    await CustomerService.del(initialValues.id).then((o) => o.data);
                    setCustomers([...customers].filter((c) => c.id !== initialValues.id));
                    setOpen(false);
                }}
                onCancel={() => setOpen(false)}
            />

            <Notification
                open={notification.open}
                message={notification.message}
                close
                color="success"
                onClose={(e) => {
                    setNotification({ ...notification, open: false });
                }}
            />

            <Modal
                open={modal.open}
                modalTitle={modal.title}
                handleClose={() => {
                    setInitialValues({
                        ...INITIAL_VALUES,
                        id: Math.random(),
                    });
                    setModal({ ...modal, open: false });
                }}
            >
                {modal.open ? (
                    <CreateCustomer
                        form={form}
                        initialValues={initialValues}
                        onSuccess={(customer) => onSuccess(customer)}
                    />
                ) : null}
            </Modal>

            <GridContainer>
                <GridItem xs={12}>
                    <Card>
                        <CardHeader
                            color="primary"
                            icon
                            style={{ display: "flex", alignItems: "center" }}
                        >
                            <CardIcon color="primary">
                                <Assignment />
                            </CardIcon>
                            <Button
                                color="primary"
                                onClick={() => {
                                    setForm("create");
                                    setModal({ ...modal, open: true, title: "Create customer" });
                                    setNotification({
                                        ...notification,
                                        message: "Customer created successfully",
                                    });
                                    setInitialValues(INITIAL_VALUES);
                                }}
                            >
                                Create customer
                            </Button>
                        </CardHeader>
                        <CardBody>
                            <div style={{ marginBottom: 10 }}>
                                <GridContainer>
                                    {user?.isAdmin === 1 && (
                                        <GridItem xs={12} sm={2}>
                                            <FormControl variant="standard" fullWidth>
                                                <InputLabel id="demo-simple-select-label">
                                                    Dealer
                                                </InputLabel>
                                                <Select
                                                    id="dealerId"
                                                    name="dealerId"
                                                    value={dlid}
                                                    onChange={(e) => {
                                                        setDlid(e.target.value);
                                                    }}
                                                    fullWidth
                                                >
                                                    {dealers.length > 1 && (
                                                        <MenuItem key={0} value={0}>
                                                            All dealers
                                                        </MenuItem>
                                                    )}
                                                    {dealers.map((dealer, index) => (
                                                        <MenuItem key={index} value={dealer.id}>
                                                            {dealer.id}. {dealer.company}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </GridItem>
                                    )}
                                    <GridItem xs={12} sm={2}>
                                        <TextField
                                            onBlur={(e) => setSearch(e.target.value)}
                                            label="Search String"
                                            helperText="[Customer Name, email, Address, etc]"
                                            fullWidth
                                        />
                                    </GridItem>
                                    <GridItem xs={12} sm={2}>
                                        <Button
                                            color="success"
                                            variant="contained"
                                            startIcon={<SearchIcon />}
                                            onClick={() => {}}
                                        >
                                            Search
                                        </Button>
                                    </GridItem>
                                </GridContainer>
                            </div>

                            <GridContainer>
                                <GridItem xs={12} sm={12}>
                                    <ReactTable
                                        columns={columns}
                                        data={customers}
                                        fetchData={fetchData(dlid, search)}
                                        pageCount={pageCount}
                                        manualPagination={true}
                                        showSorting={false}
                                    />
                                </GridItem>
                            </GridContainer>
                        </CardBody>
                    </Card>
                </GridItem>
            </GridContainer>
        </>
    );
}
