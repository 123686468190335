import { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useConfirmationDialog } from "./useConfirmationDialog";
import EditPartForm from "views/EditPart/EditPartForm";
import PartSelectionDialog from "views/HomePageSpecials/PartSelectionDialog";
import EditPartService from "services/EditPartService";
import useDealerId from "./useDealerId";
import { useSnackbar } from "./useSnackbar";

const validationSchema = Yup.object({
    title: Yup.string().required("Title is required"),
    /*description: Yup.string().required("Description is required"),
    sellingPrice: Yup.number()
        .required("Selling Price is required")
        .positive("Must be a positive number"),
    core: Yup.number().required("Core is required").positive("Must be a positive number"),*/
    fixedShippingPrice: Yup.number().positive("Must be a positive number"),
    additionalImage: Yup.string().url("Must be a valid URL"),
});

const useEditPartForm = (onSuccess) => {
    const [partNumber, setPartNumber] = useState(""); // 1876170150
    const [product, setProduct] = useState({});
    const [parts, setParts] = useState([]);
    const { openDialog, closeDialog } = useConfirmationDialog();
    const dealerId = useDealerId();
    const openSnackbar = useSnackbar();

    useEffect(() => {
        const getParts = async (dealerId) => {
            const parts = await fetchProducts(dealerId);
            setParts(parts);
        };

        getParts(dealerId);

        return () => {
            setParts([]);
        };
    }, [dealerId]);

    const formik = useFormik({
        initialValues: {
            dealerId: dealerId,
            partNumber: partNumber,
            title: "",
            description: "",
            price: "",
            sellingPrice: "",
            core: "",
            hideItem: false,
            activateItem: false,
            pickupOnly: false,
            fixedShippingPrice: "",
            additionalImage: "",
            additionalImageDefault: false,
        },
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            if ("id" in values) {
                await updatePart(values.id, values);
            } else {
                await createPart(values);
            }

            onSuccess();
            closeDialog();
        },
    });

    const fetchProducts = async (dealerId, partNumber) => {
        const { data = [] } = await EditPartService.getAll(dealerId, partNumber);
        return data;
    };

    const handleClickSearch = async () => {
        const data = await fetchProducts(dealerId, partNumber);

        if (data.length === 0) {
            openDialog({
                customDialog: true,
                fullWidth: true,
                maxWidth: "md",
                message: (
                    <PartSelectionDialog
                        partNumber={partNumber}
                        onProductSelect={(product) => {
                            setProduct(product);
                            closeDialog();

                            const sellingPrice =
                                product.price?.isActive == 0
                                    ? product.price?.originalPrice
                                    : product.price.price;

                            const productParsed = {
                                dealerId: dealerId,
                                partNumber: product.part_number,
                                title: product.name,
                                description: "",
                                price: "",
                                listPrice: product.price.price,
                                sellingPrice: sellingPrice,
                                core: product.core,
                                hideItem: "",
                                activateItem: "",
                                pickupOnly: "",
                                fixedShippingPrice: "",
                                additionalImage: "",
                                additionalImageDefault: false,
                                cost: product.cost,
                            };

                            setTimeout(() => {
                                handleClickEdit(productParsed);
                            }, 250);
                        }}
                    />
                ),
            });
        } else {
            setProduct(data[0]);
        }
    };

    const handleClickEdit = (product) => {
        openDialog({
            customDialog: true,
            fullWidth: true,
            maxWidth: "md",
            message: (
                <EditPartForm
                    formik={formik}
                    product={product}
                    onSuccess={async () => {
                        const parts = await fetchProducts(dealerId);
                        setParts(parts);
                    }}
                />
            ),
        });
    };

    const handleClickDelete = async (ids) => {
        try {
            const deletePromises = ids.map((id) => EditPartService.del(id));
            await Promise.all(deletePromises);
            openSnackbar("success", "Items deleted successfully");
            const { data = [] } = await EditPartService.getAll(dealerId, partNumber);
            setParts(data);
        } catch (error) {
            console.error("Error deleting items:", error);
            alert("Failed to delete items");
        }
    };

    const createPart = async (values) => {
        try {
            await EditPartService.post(values);
            openSnackbar("success", "Part created successfully");
        } catch (error) {
            console.error("Error creating part:", error);
            alert("Failed to create part");
        }
    };

    const updatePart = async (id, values) => {
        try {
            await EditPartService.put(id, values);
            openSnackbar("success", "Part updated successfully");
            handleClickSearch();
        } catch (error) {
            console.error("Error updating part:", error);
            alert("Failed to update part");
        }
    };

    return {
        formik,
        handleClickEdit,
        handleClickDelete,
        handleClickSearch,
        partNumber,
        setPartNumber,
        product,
        parts,
    };
};

export default useEditPartForm;
