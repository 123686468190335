import PropTypes from "prop-types";
import { format } from "date-fns";

export default function FormattedDate({ date, dateFormat = "yyyy-MM-dd HH:mm" }) {
    if (!date) return null;

    const formattedDate = format(new Date(date), dateFormat);
    return formattedDate;
}

FormattedDate.propTypes = {
    date: PropTypes.string.isRequired,
};
