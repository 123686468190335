import React from "react";
import TextField from "@material-ui/core/TextField";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

const PaymentMethodFields = ({ paymentMethodId, formik }) => {
    let gatewayCredentials = {};

    try {
        gatewayCredentials = JSON.parse(formik.values.gatewayCredentials || "{}");
    } catch (error) {
        console.error("Error parsing gatewayCredentials JSON:", error);
        gatewayCredentials = {};
    }

    const handleFieldChange = (field, value) => {
        const updatedCredentials = {
            ...gatewayCredentials,
            [field]: value,
        };
        formik.setFieldValue("gatewayCredentials", JSON.stringify(updatedCredentials));
    };

    switch (paymentMethodId) {
        case 1: // PayPal Express
            return (
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12} style={{ display: "none" }}>
                        <TextField
                            fullWidth
                            margin="dense"
                            id="apiUrl"
                            name="apiUrl"
                            label="API URL"
                            value={gatewayCredentials?.apiUrl || ""}
                            onChange={(e) => handleFieldChange("apiUrl", e.target.value)}
                            error={
                                formik.touched.gatewayCredentials?.apiUrl &&
                                Boolean(formik.errors.gatewayCredentials?.apiUrl)
                            }
                            helperText={
                                formik.touched.gatewayCredentials?.apiUrl &&
                                formik.errors.gatewayCredentials?.apiUrl
                            }
                        />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                        <TextField
                            fullWidth
                            margin="dense"
                            id="clientId"
                            name="clientId"
                            label="Client ID"
                            value={gatewayCredentials?.clientId || ""}
                            onChange={(e) => handleFieldChange("clientId", e.target.value)}
                            error={
                                formik.touched.gatewayCredentials?.clientId &&
                                Boolean(formik.errors.gatewayCredentials?.clientId)
                            }
                            helperText={
                                formik.touched.gatewayCredentials?.clientId &&
                                formik.errors.gatewayCredentials?.clientId
                            }
                        />
                    </GridItem>
                </GridContainer>
            );
        case 2: // PayPal Pro
            return (
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12} style={{ display: "none" }}>
                        <TextField
                            fullWidth
                            margin="dense"
                            id="apiUrl"
                            name="apiUrl"
                            label="API URL"
                            value={gatewayCredentials?.apiUrl || ""}
                            onChange={(e) => handleFieldChange("apiUrl", e.target.value)}
                            error={
                                formik.touched.gatewayCredentials?.apiUrl &&
                                Boolean(formik.errors.gatewayCredentials?.apiUrl)
                            }
                            helperText={
                                formik.touched.gatewayCredentials?.apiUrl &&
                                formik.errors.gatewayCredentials?.apiUrl
                            }
                        />
                    </GridItem>
                    <GridItem xs={12} sm={6} md={6}>
                        <TextField
                            fullWidth
                            margin="dense"
                            id="user"
                            name="user"
                            label="User"
                            value={gatewayCredentials?.user || ""}
                            onChange={(e) => handleFieldChange("user", e.target.value)}
                            error={
                                formik.touched.gatewayCredentials?.user &&
                                Boolean(formik.errors.gatewayCredentials?.user)
                            }
                            helperText={
                                formik.touched.gatewayCredentials?.user &&
                                formik.errors.gatewayCredentials?.user
                            }
                        />
                    </GridItem>
                    <GridItem xs={12} sm={6} md={6}>
                        <TextField
                            fullWidth
                            margin="dense"
                            id="pwd"
                            name="pwd"
                            label="Password"
                            type="text"
                            value={gatewayCredentials?.pwd || ""}
                            onChange={(e) => handleFieldChange("pwd", e.target.value)}
                            error={
                                formik.touched.gatewayCredentials?.pwd &&
                                Boolean(formik.errors.gatewayCredentials?.pwd)
                            }
                            helperText={
                                formik.touched.gatewayCredentials?.pwd &&
                                formik.errors.gatewayCredentials?.pwd
                            }
                        />
                    </GridItem>
                    <GridItem xs={12} sm={6} md={6}>
                        <TextField
                            fullWidth
                            margin="dense"
                            id="vendor"
                            name="vendor"
                            label="Vendor"
                            value={gatewayCredentials?.vendor || ""}
                            onChange={(e) => handleFieldChange("vendor", e.target.value)}
                            error={
                                formik.touched.gatewayCredentials?.vendor &&
                                Boolean(formik.errors.gatewayCredentials?.vendor)
                            }
                            helperText={
                                formik.touched.gatewayCredentials?.vendor &&
                                formik.errors.gatewayCredentials?.vendor
                            }
                        />
                    </GridItem>
                    <GridItem xs={12} sm={6} md={6}>
                        <TextField
                            fullWidth
                            margin="dense"
                            id="partner"
                            name="partner"
                            label="Partner"
                            value={gatewayCredentials?.partner || ""}
                            onChange={(e) => handleFieldChange("partner", e.target.value)}
                            error={
                                formik.touched.gatewayCredentials?.partner &&
                                Boolean(formik.errors.gatewayCredentials?.partner)
                            }
                            helperText={
                                formik.touched.gatewayCredentials?.partner &&
                                formik.errors.gatewayCredentials?.partner
                            }
                        />
                    </GridItem>
                </GridContainer>
            );
        case 3: // Quantus One
            return (
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12} style={{ display: "none" }}>
                        <TextField
                            fullWidth
                            margin="dense"
                            id="apiUrl"
                            name="apiUrl"
                            label="API URL"
                            value={gatewayCredentials?.apiUrl || ""}
                            onChange={(e) => handleFieldChange("apiUrl", e.target.value)}
                            error={
                                formik.touched.gatewayCredentials?.apiUrl &&
                                Boolean(formik.errors.gatewayCredentials?.apiUrl)
                            }
                            helperText={
                                formik.touched.gatewayCredentials?.apiUrl &&
                                formik.errors.gatewayCredentials?.apiUrl
                            }
                        />
                    </GridItem>
                    <GridItem xs={12} sm={6} md={6}>
                        <TextField
                            fullWidth
                            margin="dense"
                            id="profileId"
                            name="profileId"
                            label="Profile ID"
                            value={gatewayCredentials?.profileId || ""}
                            onChange={(e) => handleFieldChange("profileId", e.target.value)}
                            error={
                                formik.touched.gatewayCredentials?.profileId &&
                                Boolean(formik.errors.gatewayCredentials?.profileId)
                            }
                            helperText={
                                formik.touched.gatewayCredentials?.profileId &&
                                formik.errors.gatewayCredentials?.profileId
                            }
                        />
                    </GridItem>
                    <GridItem xs={12} sm={6} md={6}>
                        <TextField
                            fullWidth
                            margin="dense"
                            id="profileKey"
                            name="profileKey"
                            label="Profile Key"
                            type="text"
                            value={gatewayCredentials?.profileKey || ""}
                            onChange={(e) => handleFieldChange("profileKey", e.target.value)}
                            error={
                                formik.touched.gatewayCredentials?.profileKey &&
                                Boolean(formik.errors.gatewayCredentials?.profileKey)
                            }
                            helperText={
                                formik.touched.gatewayCredentials?.profileKey &&
                                formik.errors.gatewayCredentials?.profileKey
                            }
                        />
                    </GridItem>
                </GridContainer>
            );
        case 8: // Authorize Net
            return (
                <GridContainer>
                    <GridItem xs={12} sm={12} md={6}>
                        <TextField
                            fullWidth
                            margin="dense"
                            id="apiLoginId"
                            name="apiLoginId"
                            label="API Login Id"
                            value={gatewayCredentials?.apiLoginId || ""}
                            onChange={(e) => handleFieldChange("apiLoginId", e.target.value)}
                            error={
                                formik.touched.gatewayCredentials?.apiLoginId &&
                                Boolean(formik.errors.gatewayCredentials?.apiLoginId)
                            }
                            helperText={
                                formik.touched.gatewayCredentials?.apiLoginId &&
                                formik.errors.gatewayCredentials?.apiLoginId
                            }
                        />
                    </GridItem>
                    <GridItem xs={12} sm={6} md={6}>
                        <TextField
                            fullWidth
                            margin="dense"
                            id="transactionKey"
                            name="transactionKey"
                            label="Transaction Key"
                            value={gatewayCredentials?.transactionKey || ""}
                            onChange={(e) => handleFieldChange("transactionKey", e.target.value)}
                            error={
                                formik.touched.gatewayCredentials?.transactionKey &&
                                Boolean(formik.errors.gatewayCredentials?.transactionKey)
                            }
                            helperText={
                                formik.touched.gatewayCredentials?.transactionKey &&
                                formik.errors.gatewayCredentials?.transactionKey
                            }
                        />
                    </GridItem>
                </GridContainer>
            );
        case 9: // Stripe
            return (
                <GridContainer>
                    <GridItem xs={12} sm={12} md={6}>
                        <TextField
                            fullWidth
                            margin="dense"
                            id="publishableKey"
                            name="publishableKey"
                            label="Publishable Key"
                            value={gatewayCredentials?.publishableKey || ""}
                            onChange={(e) => handleFieldChange("publishableKey", e.target.value)}
                            error={
                                formik.touched.gatewayCredentials?.publishableKey &&
                                Boolean(formik.errors.gatewayCredentials?.publishableKey)
                            }
                            helperText={
                                formik.touched.gatewayCredentials?.publishableKey &&
                                formik.errors.gatewayCredentials?.publishableKey
                            }
                        />
                    </GridItem>
                    <GridItem xs={12} sm={6} md={6}>
                        <TextField
                            fullWidth
                            margin="dense"
                            id="secretKey"
                            name="secretKey"
                            label="Secret Key"
                            value={gatewayCredentials?.secretKey || ""}
                            onChange={(e) => handleFieldChange("secretKey", e.target.value)}
                            error={
                                formik.touched.gatewayCredentials?.secretKey &&
                                Boolean(formik.errors.gatewayCredentials?.secretKey)
                            }
                            helperText={
                                formik.touched.gatewayCredentials?.secretKey &&
                                formik.errors.gatewayCredentials?.secretKey
                            }
                        />
                    </GridItem>
                </GridContainer>
            );
        default:
            return null;
    }
};

export default PaymentMethodFields;
